import React from 'react';

import { Link } from 'react-router-dom'
import TopBar from "../../components/appbar.component"



const WorkPage = (props) => (


    <div>
        <TopBar />

    </div>
)

export default WorkPage