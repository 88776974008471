import Bed from "../assests/images/bed.jpeg";
import Map from "../assests/images/map.png";
import Found1 from "../assests/images/found1.jpeg";
import Found2 from "../assests/images/found2.jpeg";
import Found3 from "../assests/images/found3.jpeg";
import News from "../assests/images/newsp.jpeg";
import Bedding from "../assests/images/beddings.jpeg";

const data = [{
    imageUrl: Map,
    title: "Lot has been Registered",
    description: "Lot has been registered for building the foundation"

}, {
    imageUrl: Found1,
    title: "Prayers for a good Beginning",
    description: "Local priest praying before start of construction"

}, {
    imageUrl: Found2,
    title: "On going construction",
    description: "Locals gathered in support of the foundation"

}, {
    imageUrl: Found3,
    title: "Borrowing is completed for water supply",
    description: "Foundation will have it own under ground water supply for drinking and sanitaion."

}, {
    imageUrl: News,
    title: "Local Newspaper supports Foundation",
    description: "Local newspaper prints to raise awareness and generate support for the foundation"

}, {
    imageUrl: Bed,
    title: "50 Beds Donated",
    description: "FIfty bed were donated by one of our member"

}, {
    imageUrl: Bedding,
    title: "50 Beddings provided",
    description: "FIfty beddings were also donated by one of our supporters"

}]

// const data = [{ id: 3, title: "New Employee tarinig", startDate: "2021-03-30 10:30", endDate: "2021-03-30 11:30", addition_information: "All new employees" },
// { id: 5, title: "New Employee tarinig", startDate: "2021-03-30 10:30", endDate: "2021-03-30 11:30", addition_information: "All new employees" },
// { id: 38, title: "some test", startDate: "2021-03-30 10:30", endDate: "2021-03-30 11:30", addition_information: "All new employees" },
// { id: 9, title: "new test", startDate: "2021-03-30 10:30", endDate: "2021-03-30 11:30", addition_information: "All new employees" },
// { id: 28, title: "last test", startDate: "2021-03-30 10:30", endDate: "2021-03-30 11:30", addition_information: "All new employees" },
// { id: 23, title: "day test", startDate: "2021-04-29 10:30", endDate: "2021-04-30 11:30", addition_information: "All new employees" }]

export default data